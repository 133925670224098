.splash {
  background-image: radial-gradient(circle at 50% 51%, #5e5d61fa, #1d1f2cdb);
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.splash .splash-logo {
  text-align: center;
  margin: 0px;
  position: relative;
}

.splash .splash-logo img {
  max-height: 50px;
}

.loader {
  height: 16px;
  margin: 0 auto;
  width: 200px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  margin-top: 100px;
}

.loader:before {
  display: block;
  position: absolute;
  content: '';
  left: -200px;
  width: 20px;
  margin: 2px 0px;
  height: 12px;
  border-radius: 6px;
  background-color: #1d1f2c;
  animation: loading 1.5s linear infinite;
}

@keyframes loading {
  from {
    left: -200px;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}
