.container-ativacao {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px 10px;
  width: 100%;
  margin-top: 15px;
  align-items: center;
  justify-content: center;
}

.container-ativacao p:nth-child(even) {
  color: #ffffff;
  font-size: 14px;
}

.container-ativacao p:nth-child(odd) {
  color: #60637c;
  font-size: 14px;
}

.container-ativacao .column {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.container-ativacao p.detalhe-endereco {
  color: #ffffff;
  font-size: 12px;
  line-height: 30px;
}

.container-ativacao p {
  text-align: left !important;
}

.container-ativacao p {
  text-align: left !important;
}

.container-ativacao p.item-cancelado {
  color: #ff8383;
  text-transform: uppercase;
  line-height: 30px;
}

.gmnoprint a,
.gmnoprint span {
  display: none;
}

.gmnoprint div {
  background: none !important;
}

#GMapsID div div a div img {
  display: none;
}

.gm-style-cc div {
  background: none !important;
  display: none !important;
}
