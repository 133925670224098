.card-saldo-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

.card-saldo-sorocaba {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  font-weight: 500;
  font-size: 2.1em;
  color: #fff;
}

.container-header-title-refresh {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.container-refresh-btn {
  background-color: transparent;
  border-width: 0px;
  margin-left: 20px;
}

.refresh-icon {
  color: rgb(255, 255, 255);
  font-size: 15px;
}
