.card-saldo {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 5px;
  margin-top: 0px;
  background-color: rgba(19, 23, 43, 1);
  padding: 5px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
}
.card-saldo p {
  color: #ffffff;
  font-size: 30px;
  letter-spacing: 0px;
}

.card-saldo p:first-child {
  color: #ffffff;
  font-size: 12px;
  letter-spacing: 0px;
}
