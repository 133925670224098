.btn-loading {
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  color: #fff;
}
.spinner {
  position: absolute;
  right: 5%;
  margin-top: 2px;
}
