.vehicles-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: calc(100vh - 310px);
  overflow-y: auto;
  margin-top: 10px;
}

.city-footer .vehicles-container {
  height: calc(100vh - 325px);
}
