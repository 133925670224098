@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,700);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-error {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #dc7979;
  /* position: absolute; */
  margin-top: 10px;
  width: 100%;
  text-align: center;
}

.btn-loading {
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1 1;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  color: #fff;
}
.spinner {
  position: absolute;
  right: 5%;
  margin-top: 2px;
}

.splash {
  background-image: -webkit-radial-gradient(50% 51%, circle, #5e5d61fa, #1d1f2cdb);
  background-image: radial-gradient(circle at 50% 51%, #5e5d61fa, #1d1f2cdb);
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.splash .splash-logo {
  text-align: center;
  margin: 0px;
  position: relative;
}

.splash .splash-logo img {
  max-height: 50px;
}

.loader {
  height: 16px;
  margin: 0 auto;
  width: 200px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  margin-top: 100px;
}

.loader:before {
  display: block;
  position: absolute;
  content: '';
  left: -200px;
  width: 20px;
  margin: 2px 0px;
  height: 12px;
  border-radius: 6px;
  background-color: #1d1f2c;
  -webkit-animation: loading 1.5s linear infinite;
          animation: loading 1.5s linear infinite;
}

@-webkit-keyframes loading {
  from {
    left: -200px;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}

@keyframes loading {
  from {
    left: -200px;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}

.vehicles-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: calc(100vh - 310px);
  overflow-y: auto;
  margin-top: 10px;
}

.city-footer .vehicles-container {
  height: calc(100vh - 325px);
}

.card-saldo {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  margin: 5px;
  margin-top: 0px;
  background-color: rgba(19, 23, 43, 1);
  padding: 5px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
}
.card-saldo p {
  color: #ffffff;
  font-size: 30px;
  letter-spacing: 0px;
}

.card-saldo p:first-child {
  color: #ffffff;
  font-size: 12px;
  letter-spacing: 0px;
}

.card-saldo-container {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

.card-saldo-sorocaba {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  font-weight: 500;
  font-size: 2.1em;
  color: #fff;
}

.container-header-title-refresh {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.container-refresh-btn {
  background-color: transparent;
  border-width: 0px;
  margin-left: 20px;
}

.refresh-icon {
  color: rgb(255, 255, 255);
  font-size: 15px;
}

.item-activation {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 5px 0 5px 0;
  padding: 10px;
  background: -webkit-linear-gradient(#2d3048, #2d3048);
  background: linear-gradient(#2d3048, #2d3048);
}

.item-activation:hover {
  background: -webkit-linear-gradient(#282b42, #282b42);
  background: linear-gradient(#282b42, #282b42);
}

.item-activation .left {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 10px;
}

.item-activation .left p:nth-child(even) {
  color: #eaeaea;
  font-size: 14px;
}

.item-activation .left p:nth-child(odd) {
  color: #60637c;
  font-size: 13px;
}

.item-activation .right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.item-activation .right i {
  font-size: 40px;
  color: #60637c;
}

.panel .item-activation {
  cursor: pointer;
}

.container-ativacao {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px 10px;
  width: 100%;
  margin-top: 15px;
  align-items: center;
  justify-content: center;
}

.container-ativacao p:nth-child(even) {
  color: #ffffff;
  font-size: 14px;
}

.container-ativacao p:nth-child(odd) {
  color: #60637c;
  font-size: 14px;
}

.container-ativacao .column {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.container-ativacao p.detalhe-endereco {
  color: #ffffff;
  font-size: 12px;
  line-height: 30px;
}

.container-ativacao p {
  text-align: left !important;
}

.container-ativacao p {
  text-align: left !important;
}

.container-ativacao p.item-cancelado {
  color: #ff8383;
  text-transform: uppercase;
  line-height: 30px;
}

.gmnoprint a,
.gmnoprint span {
  display: none;
}

.gmnoprint div {
  background: none !important;
}

#GMapsID div div a div img {
  display: none;
}

.gm-style-cc div {
  background: none !important;
  display: none !important;
}

.item-order {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 5px 0 5px 0;
  padding: 10px;
  background: -webkit-linear-gradient(#2d3048, #2d3048);
  background: linear-gradient(#2d3048, #2d3048);
}

.item-order:hover {
  background: -webkit-linear-gradient(#282b42, #282b42);
  background: linear-gradient(#282b42, #282b42);
}

.item-order .left {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 10px;
}

.item-order .left p:nth-child(even) {
  color: #eaeaea;
  font-size: 14px;
}

.item-order .left p:nth-child(odd) {
  color: #60637c;
  font-size: 13px;
}

.item-order .right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.item-order .right i {
  font-size: 40px;
  color: #60637c;
}

.panel .item-order {
  cursor: pointer;
}

.container-compra {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px 10px;
  width: 100%;
  margin-top: 15px;
  align-items: center;
  justify-content: center;
}

.container-compra p:nth-child(even) {
  color: #ffffff;
  font-size: 14px;
}

.container-compra p:nth-child(odd) {
  color: #60637c;
  font-size: 14px;
}

.container-compra p {
  text-align: left !important;
}

.container-compra p {
  text-align: left !important;
}

* {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  outline: none;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

::-webkit-input-placeholder {
  color: #fff;
}

::-moz-placeholder {
  color: #fff;
}

:-ms-input-placeholder {
  color: #fff;
}

:-moz-placeholder {
  color: #fff;
}

body {
  background-color: #1d1f2c;
  text-align: center;
  background-image: url(/static/media/bg.f67b79f8.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
}

body::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

body::-webkit-scrollbar-thumb {
  background: #4b4d5a;
}

body::-webkit-scrollbar-track {
  background: 0 0;
}

body div div div::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

body div div div::-webkit-scrollbar-thumb {
  background: #4b4d5a;
}

body div div div::-webkit-scrollbar-track {
  background: 0 0;
}

.content {
  display: flex;
  justify-content: center;
  padding: 0 30px;
}

.btn {
  border: none;
  border-radius: 20px;
  cursor: pointer;
  display: block;
  font-size: 20px;
  font-weight: 300;
  height: 40px;
  margin: 35px 0 0;
  width: 100%;
}

.btn-green {
  background-color: #67983d;
  color: #fff;
}

header {
  padding: 50px 0 30px;
}

header .content {
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.header_urbes {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: stretch;
  border-bottom: 1px solid #fff;
}
.header_urbes > img {
  width: 100%;
}

.hidden {
  display: none;
}

.content-login-urbes {
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-self: stretch;
}

.content-login-urbes .div-urbes {
  background-color: rgb(4, 44, 98);
  align-self: stretch;
}

.form-login-urbes {
  align-self: center;
}

.logo-less {
  background-size: contain;
  display: block;
  height: 124px;
  width: 100px;
}

.logo img {
  background-size: contain;
  display: block;
  width: 117px;
}

.logoUrbes img {
  background-size: contain;
  display: block;
  width: 600px;
}

.logoZaUrbes img {
  background-size: contain;
  display: block;
  width: 200px;
}

.topo {
  align-items: center;
  color: #fff;
  display: flex;
  flex-direction: row;
  font-size: 17px;
  justify-content: space-between;
  text-transform: uppercase;
  padding-right: 10px;
}

.topo .cidade,
.topo .usuario,
.topo .sair {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 0 0 0 10px;
}

.topo .usuario {
  cursor: pointer;
}

.topo .sair {
  cursor: pointer;
}

.topo .cidade .fas,
.topo .usuario .fas,
.topo .sair .fas {
  margin: 0 10px 0 0;
}

.topo .usuario span {
  text-decoration: underline;
}

h1 {
  color: #fff;
  display: block;
  /*font-size: 35px;*/
  font-weight: normal;
  text-transform: uppercase;
}

h5 {
  color: #fff;
  display: block;
  /*font-size: 20px;*/
  font-weight: normal;
}

h5 a {
  margin: 0 0 0 6px;
}

.custom-select {
  background-color: #4b4d5a;
  display: block;
  height: 40px;
  position: relative;
  width: 100%;
}

.custom-select:before {
  content: '';
  display: block;
  height: 0px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 15px solid #1d1f2c;
  position: absolute;
  top: 15px;
  right: 20px;
  width: 0px;
  z-index: 1;
}

.custom-select select option {
  margin: 40px;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

.custom-select select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  background-image: none;
  border: none;
  box-shadow: none;
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  height: 40px;
  padding: 0 10px;
  position: relative;
  text-transform: uppercase;
  width: 110%;
  z-index: 2;
}

.custom-select.v2 {
  border-bottom: 2px solid #fff;
  background: none;
}

.custom-select.v2:before {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 15px solid #fff;
  top: 22px;
}

form {
  display: block;
  margin: 0 auto;
  text-align: left;
  width: 310px;
}

form a {
  color: #fff;
  display: inline-block;
  font-size: 16px;
}

form .forgot-password {
  text-align: center;
  margin: 30px 0 0;
  text-decoration: none;
  width: 100%;
}

form .form-group {
  display: inline-block;
  margin: 15px 0 0;
  width: 100%;
  position: relative;
}

form .form-group input {
  border: none;
  border-bottom: 2px solid #fff;
  background: none;
  color: #fff;
  display: block;
  font-size: 18px;
  padding: 0 10px;
  width: 100%;
}

form .form-group .estacionar {
  width: 100%;
}

form .form-group .radios {
  flex-direction: row;
}

form .form-group label {
  font-size: 12px;
  vertical-align: top;
}

form .form-group .app-error {
  font-size: 13px;
}

/* HOME */
.home {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
}

.col {
  align-items: flex-start;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 10px 0 10px;
}

.col-1 {
  width: 39%;
}

.col-2 {
  width: 20%;
  flex: 0.5 1;
}

.box-payment-new-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 10px;
  text-align: left;
  width: 100%;
  position: relative;
}

.box-payment-new-card .scroll-value {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.box {
  background-color: rgba(42, 41, 47, 0.85);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 15px;
  margin-top: 10px;
  text-align: left;
  width: 100%;
  position: relative;
  border-radius: 9px;
}

.box-promo {
  background-color: #43455a;
  color: '#fff';
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  padding: 15px;
  text-align: center;
  margin-top: 10px;
  width: 100%;
}

.box-sorocaba {
  background-color: rgba(42, 41, 47, 0.85);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 15px;
  margin-top: 10px;
  text-align: left;
  width: 100%;
  position: relative;
  border-radius: 9px;
}

.height-box-card {
  height: calc(100vh - 479px);
}

.box:first-child {
  margin: 0;
}

.box-sorocaba:first-child {
  margin: 0;
}

.box h4 {
  color: #fff;
  display: block;
  font-size: 18px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.box h3 {
  color: #fff;
  font-size: 0.9em;
  display: block;
  text-transform: uppercase;
}

.box-sorocaba h3 {
  color: #fff;
  font-size: 0.9em;
  display: block;
  text-transform: uppercase;
}

.box-sorocaba h4 {
  color: #fff;
  font-size: 0.7em;
  display: block;
  text-transform: uppercase;
  text-align: center;
}

.box .saldo {
  color: #fff;
  display: block;
  font-size: 40px;
}

.box .scroll-value {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.box-sorocaba .scroll-value {
  /* overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center; */
  display: grid;
  grid-template-columns: 7vw 7vw;
  grid-gap: 5px 15px;
  width: 100%;
  margin-top: 15px;
}

.box .valor {
  background-color: #43455a;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  height: 40px;
  line-height: 40px;
  /* margin-top: 2%; */
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: 48%;
  border: none;
  cursor: pointer;
  flex-grow: 0;
  flex-shrink: 0;
}

.box-sorocaba .valor {
  background-color: #43455a;
  color: #fff;
  display: flex;
  justify-content: center;

  line-height: 35px;
  margin-top: 1%;
  text-decoration: none;
  text-transform: uppercase;
  width: 90%;
  border: none;
  cursor: pointer;
}

.box .valor:hover {
  background-color: #73768c;
}

.box-sorocaba .valor:hover {
  background-color: #73768c;
}

.cards {
  overflow-y: auto;
  width: 100%;
}

.cartao {
  align-items: center;
  background-color: #43455a;
  color: #fff;
  display: flex;
  flex-direction: row;
  font-size: 22px;
  height: 35px;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 0 10px;
  text-decoration: none;
  text-transform: uppercase;
  width: 99%;
  border: none;
  cursor: pointer;
}

.cartao:hover {
  background-color: #73768c;
}

.cartao.active {
  background-color: #73768c;
}

.cartao .bandeira {
  background-color: #fff;
  display: block;
  height: 25px;
  width: 36px;
  background: url(/static/media/bandeiras.cbb4bb95.png) 0 0 no-repeat;
}

.cartao span {
  font-size: 14px;
}

.cartao .bandeira.VISA {
  background-position: -8px -6px;
}
.cartao .bandeira.MASTERCARD {
  background-position: -60px -6px;
}
.cartao .bandeira.AMEX {
  background-position: -315px -6px;
}
.cartao .bandeira.ELO {
  background-position: -163px -6px;
}
.cartao .bandeira.HIPER {
  background-position: -112px -6px;
}

.card-btn-box {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.top-margin {
  margin-top: 15px;
}

.box-sorocaba .add {
  background-color: #2d3048;
  border: none;
  box-shadow: 0px 17px 10px -10px rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  color: #12172b;
  cursor: pointer;
  display: block;
  font-size: 40px;
  font-weight: 700;
  height: 50px;
  line-height: 50px;
  z-index: 2;
  margin: 20px auto 0;
  width: 50px;
}

.box-sorocaba .add:hover {
  background-color: #73768c;
}

.box .add {
  background-color: #73768c;
  border: none;
  border-radius: 5%;
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-weight: 500;
  height: 35px;
  line-height: 35px;
  z-index: 2;
  margin: 20px auto 0;
  width: 120px;
  color: #fff;
}

.box .add:hover {
  background-color: #50515c;
}

.box .carro {
  background-color: #43455a;
  color: #fff;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 15px;
  position: relative;
  text-decoration: none;
  width: 100%;
}

.box .carro:last-child {
  margin-bottom: 0px;
}

.box .carro .modelo {
  font-size: 12px;
  text-transform: uppercase;
  z-index: 2;
}

.box .carro .placa {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 400;
  z-index: 2;
}

.box .carro .ativacao {
  font-size: 11px;
  display: flex;
  flex-direction: row;
  z-index: 2;
}

.box .carro .ativacao .contador {
  background-color: #1d1f2c;
  width: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-radius: 4px;
  padding: 5px;
  font-size: 14px;
  z-index: 2;
  font-weight: 600;
}

.box .carro .ativacao .btn-renovar {
  padding: 2px;
  border-radius: 4px;
  margin-left: 5px;
  width: 30px;
  cursor: pointer;
}

.box .carro .ativacao .btn-renovar:hover {
  background-color: #cecece;
}

.box .carro .ativacao .btn-renovar i {
  color: #9798a2;
  font-size: 14px;
}

.box .carro .ativacao .contador i:first-child {
  font-size: 18px;
  color: #9798a2;
  z-index: 2;
}

.box .carro .ativacao .estacionar {
  background-color: #64853d;
  color: #fff;
  display: flex;
  flex-direction: row;
  height: 27px;
  font-size: 12px;
  font-weight: 500;
  padding: 0 30px;
  margin-top: 5px;
  width: auto;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.box .carro .ativacao .estacionar:hover {
  background-color: #486823;
}

.box .carro .img-carro {
  background-image: url(/static/media/car1.1c3a8a4d.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  height: 100%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 130px;
  z-index: 1;
}

.box .carro .img-moto {
  background-image: url(/static/media/motocyle1.6328dce4.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  height: 100%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 130px;
  z-index: 1;
}

.box .carro .img-carga {
  background-image: url(/static/media/truck.b6f342c7.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  height: 100%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 150px;
  z-index: 1;
}

.box .carro .excluir {
  align-items: center;
  background-color: #d9544f;
  color: #632625;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  -webkit-transition: width linear 0.25s;
  transition: width linear 0.25s;
  width: 0px;
  z-index: 3;
  border: none;
  padding: 0px;
  cursor: pointer;
}

.box .carro .excluir:hover {
  background-color: #c74743;
}

.box .carro .excluir i {
  font-size: 14px;
}

.box .carro:hover .excluir {
  width: 40px;
}

.tabs {
  display: inline-block;
  margin: 10px 0 0;
  position: relative;
  width: 100%;
}

.input {
  position: absolute;
  opacity: 0;
}

.label {
  background: #1d1f2c;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-weight: 700;
  height: 45px;
  line-height: 45px;
  left: 0;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: 0;
  width: 50%;
  z-index: 3;
}

.label-promo {
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 14px;
  text-transform: uppercase;
}

.label-app {
  color: #fff !important;
  margin: 20px;
  cursor: pointer;
  display: block;
  text-align: center !important;
  font-size: 14px;
}

.label-code {
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

.label.lb2 {
  left: auto;
  right: 0;
}

.input:checked + .label {
  background: #43455a;
}

.panel {
  background: #43455a;
  color: #fff;
  display: none;
  margin: 45px 0 0;
  padding: 15px;
  width: 100%;
  height: calc(100vh - 300px);
  overflow-y: auto;
}

.city-footer .panel {
  height: calc(100vh - 378px);
}

.input:checked + .label + .panel {
  display: block;
}

/* MODAL */
.modal {
  background-color: hsla(0, 0%, 4%, 0.86);
  display: block;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
}

.modal-content {
  align-items: center;
  background-color: #23252e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 50%;
  padding: 20px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
  width: 450px;
  max-height: calc(90% + 40px);
  overflow-y: scroll;
}

.modal-content .fa-window-close {
  color: #61637c;
  cursor: pointer;
  display: block;
  font-size: 26px;
  height: 26px;
  position: absolute;
  right: 20px;
  top: 20px;
  width: 26px;
  z-index: 1;
}

.modal-content h3 {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 400;
  /* margin: 0 0 35px; */
  text-align: center;
  text-transform: uppercase;
}

.modal-content p {
  color: #fff;
  font-size: 16;
  font-weight: 400;
  line-height: 20px;
  padding: 10px;
  margin: 0 0 15px;
  text-align: center;
}

.modal-content p.upper {
  font-size: 20px;
  padding-top: 10px;
  line-height: 26px;
}

.modal-content p.small {
  font-size: 12px;
  line-height: 14px;
}

.modal-content .estacionar p {
  font-size: 14px;
}

.modal-content .placa {
  background-color: #969696;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 18px;
  margin: 5px auto 5px;
  padding: 10px 30px;
  text-transform: uppercase;
}

.modal-content .placa span {
  display: block;
  font-size: 20px;
  line-height: 18px;
  font-weight: 600;
  color: #43455a;
  margin: 0;
}

.modal-content .placa span:first-child {
  display: block;
  font-size: 14px;
  font-weight: normal;
  color: #43455a;
  margin: 0;
}

.modal-content button {
  color: #fff;
  display: inline-block;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  font-weight: 400;
  padding: 0 30px;
  width: auto;
  border: none;
  cursor: pointer;
}

.modal-content button.btn-red {
  background-color: #993b3c;
}

.modal-content button.btn-yellow {
  background-color: #b5911f;
  margin: 0 10px 0 0;
}

.modal-content button.btn-ok {
  background-color: #61637c;
}

.modal-content button.btn-cancel {
  color: #12172b;
}

.modal-content form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
}

.modal-content form .form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-content form label {
  color: #61637c;
  display: block;
  text-align: left;
}

.modal-content form p span {
  color: #61637c;
  display: block;
  text-align: left;
}

.modal-content form input {
  border-color: #61637c;
  padding: 0;
}

.modal-content form .radios {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: space-around;
}

.modal-content form .radios .radio:not(:first-child) {
  padding-left: 10px;
}

.modal-content form input[type='radio'] {
  display: none;
}

.modal-content form .radio label {
  align-items: center;
  background-color: #61637c;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  height: 75px;
  width: 75px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  flex-direction: column;
  justify-content: center;
}

.modal-content form .radio label:hover {
  background-color: #454868;
}

.modal-content form .radio label .fas {
  display: block;
  font-size: 22px;
  line-height: 22px;
  margin: 0 0 5px;
}

.modal-content form .radio input:checked + label {
  background-color: #454868;
}

.modal-content form .radio input:checked + label:hover {
  background-color: #454868;
}

.modal-content form button {
  margin-top: 0;
}

.modal-confirm {
  background-color: hsla(0, 0%, 4%, 0.86);
  display: flex;
  flex: 1 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.box-content input[type='radio'] {
  display: none;
}

.modal-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

.box-content .radio label {
  align-items: center;
  background-color: #61637c;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  flex-direction: column;
  justify-content: center;
  width: 90px;
  height: 90px;
}

.box-content form .radio label:hover {
  background-color: #454868;
}

.box-content .radio label .fas {
  display: block;
  font-size: 22px;
  line-height: 22px;
  margin: 0 0 5px;
}

.box-content .radio input:checked + label {
  background-color: #454868;
}

.box-content .radio input:checked + label:hover {
  background-color: #454868;
}

.box-content .radios {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.form-group,
.form-credit-card .form-group {
  align-items: flex-start !important;
  width: 60%;
}

.form-edit-profile .form-group {
  align-items: flex-start !important;
  width: 100%;
}

.form-edit-profile .form-group,
.form-credit-card .form-group label {
  color: #9798a2;
  text-align: left;
}

.form-edit-profile .form-group,
.form-credit-card .form-group input {
  font-size: 14px;
}

.form-edit-profile .form-group,
.form-credit-card button {
  margin: 25px 0 0 !important;
}

.form-edit-profile .buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row !important;
}

.form-edit-profile .cpf {
  font-size: 25px;
}

form .form-register .form-group .radios .radio {
  background-color: #5d5d5d;
  float: left;
  width: 140px;
  padding: 10px;
  border-radius: 5px;
  margin: 0;
}

form .form-register .form-group .radios .radio:first-child {
  margin-right: 10px;
}

form .form-register .form-group .radios .radio input {
  width: auto;
}

form .form-register .form-group .radios .radio label {
  font-size: 14px;
  color: #fff;
  margin-left: 5px;
}

form .form-register .form-group .radios .radio input {
  float: left;
}

.btn-form {
  color: #fff;
  display: inline-block;
  height: 35px;
  line-height: 35px;
  font-size: 18px;
  font-weight: 400;
  padding: 0 30px;
  width: auto;
  border: none;
  cursor: pointer;
  background-color: #b5911f;
  margin: 0 10px 0 0;
  text-decoration: none;
}

.credit-card {
  background-color: rgba(112, 128, 144, 1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.credit-card .flag {
  color: #fff;
  font-size: 40px;
}

.credit-card .number {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1;
}

.credit-card .info {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.credit-card .info .name {
  margin-right: 10px;
}

.credit-card .info .name p,
.credit-card .info .date p {
  color: #fff;
  font-size: 13px;
  letter-spacing: 1;
  text-align: left;
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 0px;
}

.credit-card .info .name h4,
.credit-card .info .date h4 {
  color: #fff;
  font-size: 13px;
  letter-spacing: 1;
  text-align: left;
  margin: 0px;
}

.spinner-input {
  position: absolute;
  top: 50%;
  right: 0;
}

.account-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;
  height: calc(100vh - 200px);
}

.city-footer .account-box {
  height: calc(100vh - 255px);
}

.account-box .box {
  flex: 1 1;
  overflow-y: auto;
}

.account-box .box:nth-child(2) {
  flex: 2 1;
}

.payment-type {
  margin: 5px;
}

.register-form .custom-select,
.register-form .custom-select select {
  height: 40px;
}

.register-form .custom-select select {
  font-size: 18px;
}

.register-form .custom-select:before {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 15px solid #1d1f2c;
}

.register-form .form-group {
  margin-bottom: 0px;
}

.register-form .form-group input {
  font-size: 18px;
}

.register-form .btn {
  height: auto;
  font-size: 18px;
  padding: 10px;
}

.icon {
  font-size: 24px;
  color: #fff;
  position: absolute;
  left: 20px;
  top: 28px;
}

footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
}

footer img {
  width: 100%;
}

/* MEDIA QUERY */
@media only screen and (max-width: 1000px) {
  header {
    padding: 20px 0;
  }

  header .content {
    flex-direction: column;
  }

  .logo img {
    margin: 0 auto 20px;
  }

  .topo {
    font-size: 14px;
    width: 100%;
  }

  .topo .cidade,
  .topo .usuario {
    margin: 0;
  }

  .content {
    padding: 0 15px;
  }

  .box-sorocaba .scroll-value {
    grid-template-columns: 30vw 30vw;
    grid-gap: 13px 26px;
  }

  .home {
    flex-direction: column;
  }

  .col {
    margin: 0 0 10px;
    width: 100%;
  }

  h1 {
    font-size: 30px;
  }

  form {
    width: 300px;
  }

  .modal-content {
    width: 96%;
  }

  .modal-content form {
    width: 88%;
  }

  .footer {
    padding: 10px;
    margin: 10px;
    text-indent: center;
    margin: 0 auto;
  }

  footer {
    margin-top: 30px;
    position: relative;
  }
}

#blip-chat-open-iframe {
  display: none !important;
}

#blip-chat-container {
  height: 100% !important;
  bottom: 0 !important;
  right: 0 !important;
}

#blip-chat-iframe {
  position: absolute !important;
  top: 0 !important;
  right: -400px !important;
  bottom: 0 !important;
  height: 100% !important;
  max-height: 100% !important;
  -webkit-transform: none !important;
          transform: none !important;
  opacity: 1 !important;
  box-shadow: none !important;
  -webkit-transition: 0.5s !important;
  transition: 0.5s !important;
}

#blip-chat-iframe.blip-chat-iframe-opened {
  right: 0 !important;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.2) !important;
}

.btn-estacionar {
  background-color: #2d3048;
  border: none;
  float: left;
  border-radius: 5%;
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-weight: 800;
  height: 35px;
  line-height: 35px;
  z-index: 2;
  margin: 20px auto 0px 10px;
  width: 120px;
  color: #fff;
}

.btn-estacionar:hover {
  background-color: #50515c;
}
.flex-row {
  display: flex;
  flex-direction: row;
}

