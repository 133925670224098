.app-error {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #dc7979;
  /* position: absolute; */
  margin-top: 10px;
  width: 100%;
  text-align: center;
}
