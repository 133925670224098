.container-compra {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px 10px;
  width: 100%;
  margin-top: 15px;
  align-items: center;
  justify-content: center;
}

.container-compra p:nth-child(even) {
  color: #ffffff;
  font-size: 14px;
}

.container-compra p:nth-child(odd) {
  color: #60637c;
  font-size: 14px;
}

.container-compra p {
  text-align: left !important;
}

.container-compra p {
  text-align: left !important;
}
