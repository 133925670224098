.item-activation {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 5px 0 5px 0;
  padding: 10px;
  background: linear-gradient(#2d3048, #2d3048);
}

.item-activation:hover {
  background: linear-gradient(#282b42, #282b42);
}

.item-activation .left {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 10px;
}

.item-activation .left p:nth-child(even) {
  color: #eaeaea;
  font-size: 14px;
}

.item-activation .left p:nth-child(odd) {
  color: #60637c;
  font-size: 13px;
}

.item-activation .right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.item-activation .right i {
  font-size: 40px;
  color: #60637c;
}

.panel .item-activation {
  cursor: pointer;
}
